import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wideContainer pb-10"},[_c(VCard,{staticClass:"backAdBlock",attrs:{"outlined":""}},[_c(VContainer,[_c(VRow,{staticClass:"pb-10"},[_c(VCol,{attrs:{"cols":"12","xxl":"9","xl":"9","lg":"9","md":"9"}},[_c('h2',{staticClass:"whiteColor pb-2 pt-10"},[_vm._v("Остались вопросы?")]),_c('span',{staticClass:"whiteColor adDesc d-block pb-10"},[_vm._v(" Получите бесплатную профессиональную консультацию "),_c('br'),_vm._v(" от лучших кредитных экспертов ")]),_c(VBtn,{staticClass:"adBtn",attrs:{"color":"secondary"},on:{"click":_vm.openForm}},[_vm._v("Консультация")])],1),_c(VCol,{attrs:{"cols":"12","xxl":"3","xl":"3","lg":"3","md":"3"}},[_c(VImg,{staticClass:"mt-10",attrs:{"src":require("../assets/zvonok.png")}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }