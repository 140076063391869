<template>
  <section class="pt-5">
    <v-container>
      <h2 class="pb-10">Преимущества нашего предложения</h2>
      <v-row>
        <v-col cols="12" md="6" xl="6" xxl="6">
          <v-card class="square">
            <v-img class="squareBack1" src="../assets/back1.png">
              <v-card-title class="squareDesc">
                Указание цели не требуется. <br />
                Тратьте, на то, что желаете. <br />
                Предоставим деньги на <br />
                покупки, ремонт и многое другое.
              </v-card-title>
              <v-img class="squareImg" src="../assets/1.png" />
            </v-img>
          </v-card>
          <v-card class="square">
            <v-img class="squareBack2" src="../assets/back2.png">
              <v-card-title class="squareDesc">
                Оформим по двум документам. <br />
                Для одобрения не требуется <br />
                справок о доходах и <br />
                поручительство.
              </v-card-title>

              <img class="squareImg" src="../assets/2.png" />
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" xl="6" xxl="6">
          <v-card class="square">
            <v-img class="squareBack3" src="../assets/back3.png">
              <v-card-title class="squareDesc">
                Кредитная история не важна. <br />
                Одобрим с любыми долгами, <br />
                займами в МФО и просрочками.
              </v-card-title>

              <img class="squareImg" src="../assets/3.png" />
            </v-img>
          </v-card>
          <v-card class="square">
            <v-img class="squareBack4" src="../assets/back4.png">
              <v-card-title class="squareDesc">
                Деньги в день обращения. <br />
                Оставьте заявку и получите <br />
                решение в течении 15 минут. <br />
                Деньги будут у вас в течение дня.
              </v-card-title>

              <img class="squareImg" src="../assets/4.png" />
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "SquaresComp",
  data() {
    return {};
  },
};
</script>

<style>
.squareDesc {
  color: #fff;
  padding: 55px 65px;
  font-weight: bold;
}
.square {
  /* max-width: 700px; */
  margin: 20px 0px 20px 0px;
}
.squareImg {
  height: 70%;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.squareBack1 {
  background-color: #5b7698;
}
.squareBack2 {
  background: #8c696f;
}
.squareBack3 {
  background: #9faf95;
}
.squareBack4 {
  background: #bea686;
}
@media (max-width: 320px) {
  .squareImg {
    display: none;
  }
}
@media (max-width: 481px) {
  .squareImg {
    display: none;
  }
}
@media (max-width: 641px) {
  .squareImg {
    display: none;
  }
}
@media (max-width: 1600px) {
  .squareImg {
    height: 75%;
  }
}
</style>
