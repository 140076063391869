import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wideContainer"},[_c(VCard,{staticClass:"banner"},[_c(VContainer,[_c('h1',{staticClass:"whiteColor pt-8 pb-5"},[_vm._v(" Кредит"),_c('br'),_vm._v(" без отказа ")]),_c(VImg,{staticClass:"mainImage pt-5",attrs:{"src":require("../assets/slide-pics.png")}})],1),_c(VContainer,[_c('br'),_c(VRow,{staticClass:"pb-10"},[_c(VCol,{attrs:{"cols":"12","xxl":"4","xl":"4","lg":"4","md":"4"}},[_c(VBtn,{staticClass:"bannerBtn",on:{"click":_vm.openForm}},[_vm._v("Отправить заявку")])],1),_c(VCol,{attrs:{"cols":"12","xxl":"4","xl":"4","lg":"4","md":"4"}},[_c('span',{staticClass:"checkList"},[_c(VIcon,{staticClass:"checkListDots"},[_vm._v("mdi-exclamation")]),_vm._v("Без подтверждения дохода ")],1),_c('span',{staticClass:"checkList"},[_c(VIcon,{staticClass:"checkListDots"},[_vm._v("mdi-exclamation")]),_vm._v("С просрочками и микрозаймами ")],1)]),_c(VCol,{attrs:{"xxl":"4","xl":"4","lg":"4","md":"4"}},[_c('span',{staticClass:"checkList"},[_c(VIcon,{staticClass:"checkListDots"},[_vm._v("mdi-exclamation")]),_vm._v("С плохой КИ ")],1),_c('span',{staticClass:"checkList"},[_c(VIcon,{staticClass:"checkListDots"},[_vm._v("mdi-exclamation")]),_vm._v("Деньги в день обращения ")],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }