<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      height="90"
      :class="{
        headOnScrollNone: scrollPosition < 1,
        headOnScroll: scrollPosition > 1,
      }"
    >
      <v-container class="d-flex">
        <a href="">
          <div class="d-flex align-center logo-text">
            <v-img
              alt="логотип"
              class="shrink mr-5"
              contain
              src="../src/assets/logo.png"
              transition="scale-transition"
              width="48"
            />
            МосИнКомм
          </div>
        </a>
        <v-spacer />
        <a class="headPhone2" href="tel:+74954318843"
          ><v-icon class="phoneIcon2" color="black">
            mdi-phone-outline
          </v-icon></a
        >
        <a class="headPhone" href="tel:+74954318843"> +7 (495) 431-88-43 </a>
        <v-btn outlined class="btn-head ml-10" @click="openForm"
          >Перезвоните мне</v-btn
        >
      </v-container>
    </v-app-bar>

    <v-main>
      <BannerComp />
      <CalcComp />
      <SquaresComp />
      <FaqComp />
      <AdBlockComp />
      <FooterComp />
    </v-main>
    <v-btn color="secondary" class="floatBtn" @click="openForm"
      >Проверить кредитную историю</v-btn
    >
  </v-app>
</template>

<script>
import BannerComp from "./components/BannerComp.vue";
import CalcComp from "./components/CalcComp.vue";
import SquaresComp from "./components/SquaresComp.vue";
import FaqComp from "./components/FaqComp.vue";
import AdBlockComp from "./components/AdBlockComp.vue";
import FooterComp from "./components/FooterComp.vue";

export default {
  name: "App",

  components: {
    BannerComp,
    CalcComp,
    SquaresComp,
    FaqComp,
    AdBlockComp,
    FooterComp,
  },

  data: () => ({
    scrollPosition: null,
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openForm() {
      this.$store.commit("SET_MAINDIALOG");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  created() {},
  computed: {},
};
</script>
<style>
html {
  background-color: #fff;
}
body {
  font-family: "Nissan Brand", sans-serif, Arial, Helvetica !important;
}
h1 {
  font-size: 68px;
  font-weight: normal;
  line-height: 70px;
  color: white;
  margin: 0;
  text-shadow: 1px 1px 11px #7c7a7a;
}
h2 {
  font-weight: normal;
  font-size: 30px;
}
h4 {
  color: #000;
  font-size: 17px;
}
p {
  font-size: 14px;
  color: #808080;
}
li {
  font-size: 14px;
  color: #808080;
}
.v-application {
  font-family: "Nissan Brand", sans-serif;
  line-height: 1.5;
}
.logo-text {
  font-size: 30px;
  line-height: 85px;
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
  color: #000 !important;
}
.v-sheet.v-card {
  border-radius: 10px;
}
.headPhone {
  line-height: 5;
}
.headPhone2 {
}
.phoneIcon {
  background-color: #ffed00;
  padding: 10px;
  border-radius: 90%;
  margin-right: 15px;
}
.phoneIcon2 {
  background-color: #ffed00;
  padding: 10px;
  border-radius: 90%;

  margin: 21px;
}

.btn-head {
  border-color: #ffed00;
  color: #ffed00 !important;
  border-radius: 50px;
  text-transform: none;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  font-size: 17px !important;
  letter-spacing: 0px !important;
  margin-top: 14px;
}
.v-btn__content {
  color: #000 !important;
}
.v-btn::before {
  background-color: none !important;
}
.v-btn:focus {
  background-color: none !important;
}
.headOnScroll {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.headOnScrollNone {
  box-shadow: none !important;
}
.floatBtn {
  width: auto;
  position: fixed;
  bottom: 10px;
  display: block;
  z-index: 12;
  margin-left: calc(50% - 164px);
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  padding: 0 20px;
}
.wideContainer {
  margin: 0 60px;
}
@media (max-width: 320px) {
  .wideContainer {
    margin: 0 0px;
  }
  .v-card__title {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 481px) {
  .wideContainer {
    margin: 0 0px;
  }
  .v-card__title {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 641px) {
}
@media (max-width: 745px) {
  .btn-head {
    display: none !important;
  }
  .headPhone {
    display: none;
  }
}
@media (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (max-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (max-width: 1281px) {
  /* hi-res laptops and desktops */
}
@media (min-width: 1264px) {
  .container {
    max-width: 1460px;
  }
}
@media (max-width: 1600px) {
  .container {
    max-width: 1370px;
  }
}
</style>
