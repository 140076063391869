<template>
  <section class="pt-5 pb-5">
    <v-container class="pb-5">
      <h2 class="pb-10">Ответы на часто задаваемые вопросы</h2>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in faqs" :key="i">
          <v-expansion-panel-header> {{ item.name }} </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ item.desc }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="divider pb-10"></div>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "FaqComp",
  data() {
    return {
      faqs: [
        {
          name: "Смогу ли я получить кредит в банке имея плохую кредитную историю?",
          desc: "Да, конечно. Мы работаем с такими ситуациями.",
        },
        {
          name: "Я не имею официального дохода. Дадут ли мне кредит?",
          desc: "Да, наши клиенты получают кредит по двум документам без подтверждения дохода.",
        },
        {
          name: "Могу ли я получить аванс?",
          desc: "После подписания договора, вы можете получить часть или всю сумму сразу в день обращения.",
        },
        {
          name: "У меня были судимости и стоп-листы, смогу ли я получить кредит?",
          desc: "Да, конечно. Мы работаем со всеми клиентами и ситуациями любой сложности.",
        },
        {
          name: "Смогу ли я получить кредит имея действующие просрочки и задолженность по коммунальным платежам?",
          desc: "Да, конечно. Мы закроем за Вас все долги перед кредиторами или органами исполнительной власти, и одобрим кредит на выгодных для Вас условиях.",
        },
      ],
    };
  },
};
</script>
<style>
.v-item-group {
  margin-left: 0px !important;
}
.v-expansion-panel-header {
  font-size: 20px;
  margin: 20px 0px 20px 0px;
  min-height: 80px;
}
.v-expansion-panel-content__wrap {
  font-size: 20px;
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
}
.v-expansion-panel-header--active {
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
}
.v-expansion-panel::before {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.divider {
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
  width: 100%;
}
</style>
