<template>
  <section class="wideContainer pb-10">
    <v-card outlined class="backAdBlock">
      <v-container>
        <v-row class="pb-10">
          <v-col cols="12" xxl="9" xl="9" lg="9" md="9">
            <h2 class="whiteColor pb-2 pt-10">Остались вопросы?</h2>
            <span class="whiteColor adDesc d-block pb-10">
              Получите бесплатную профессиональную консультацию <br />
              от лучших кредитных экспертов
            </span>
            <v-btn class="adBtn" color="secondary" @click="openForm"
              >Консультация</v-btn
            >
          </v-col>
          <v-col cols="12" xxl="3" xl="3" lg="3" md="3">
            <v-img src="../assets/zvonok.png" class="mt-10" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </section>
</template>
<script>
export default {
  name: "AdBlockComp",
  data() {
    return {};
  },
  methods: {
    openForm() {
      this.$store.commit("SET_MAINDIALOG");
    },
  },
};
</script>
<style>
.whiteColor {
  color: #fff;
}
.adDesc {
  font-size: 20px;
}
.backAdBlock {
  background: linear-gradient(#39ab92, #319e9a);
}

.adBtn {
  border-radius: 6px;
  color: black;
  padding: 40px 73px 40px 73px !important;
  text-transform: none;
}
@media (max-width: 320px) {
  .adImg {
    display: none;
  }
  .adBtn {
    width: 100%;
  }
}
@media (max-width: 481px) {
  .adImg {
    display: none;
  }
  .adBtn {
    width: 100%;
  }
}
</style>
