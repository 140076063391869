import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mainDialog: false,
  },
  getters: {
    MAINDIALOG: (state) => {
      return state.mainDialog;
    },
  },
  mutations: {
    SET_MAINDIALOG: (state) => {
      state.mainDialog = !state.mainDialog;
    },
  },
  actions: {},
  modules: {},
});
