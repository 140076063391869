<template>
  <v-footer color="white" padless class="pt-10 pb-10">
    <v-container>
      <v-row>
        <v-col class="" md="6" cols="12">
          <h4 class="pb-5">Пример расчета займа:</h4>
          <ol>
            <li>
              Заемщик берет заём размером в 1 000 000 рублей на срок 365 дней,
              процент за пользование кредитом составляет 6,3% в год.
            </li>
            <li>
              Переплата за год пользования составит: (1 000 000 * 5,44)/100% =
              54 400 рублей.
            </li>
            <li>Следовательно, переплата в день: 54 400/365 = 149 рублей.</li>
            <li>
              Заём Был взять на срок 365 дней, следовательно, переплата за
              пользование займом составляе: 365 * 149 = 54 400 рублей.
            </li>
            <li>
              Общая сумма к выплате по займу: 1 000 000 + 54 400 = 1 054 400
              рублей.
            </li>
            <li>Срок кредитования - от 60 дней до 9 125 дней.</li>
            <li>Процентная ставка - от 6,3% до 23% годовых.</li>
          </ol>
        </v-col>
        <v-col class="" md="6" cols="12">
          <h4 class="pb-5">Требования к заемщику:</h4>
          <p>
            Возраст от 18 лет; <br />
            Паспорт гражданина РФ;<br />
            Данные СНИЛС.
          </p>

          <p>
            ООО «НТС-ресурс» 117105, г. Москва, Варшавское шоссе, д.25А, стр.6,
            пом.III, к.139 ИНН 7710941710, КПП 772301001, ОКПО 17729053 ОГРН
            1137746506424 Лицензии: ПАО «Совкомбанк» №963, АО «Банк БЖФ» №3138,
            ПАО КБ «Восточный» №1460, ПАО «Норвик Банк» №902
          </p>
          <h4>© 2023</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>
<style>
li {
  margin: 14px 0px 14px 0px;
}
.v-application ol {
  padding-left: 10px;
}
</style>
