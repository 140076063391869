import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pt-5 pb-5"},[_c(VContainer,{staticClass:"pb-5"},[_c('h2',{staticClass:"pb-10"},[_vm._v("Ответы на часто задаваемые вопросы")]),_c(VExpansionPanels,_vm._l((_vm.faqs),function(item,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(item.desc)+" ")])],1)}),1),_c('div',{staticClass:"divider pb-10"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }