<template>
  <v-container>
    <div class="text-center">
      <v-dialog v-model="dialog4" max-width="290">
        <v-card>
          <v-card-text class="text-center pt-5">
            Ваша заявка принята!
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--График платежей по сумме-->
      <v-dialog v-model="dialog" width="1200" class="">
        <v-card class="">
          <v-toolbar dark color="primary">
            <v-spacer></v-spacer>

            <!-- <a class="phone" href="tel:+7 (495) 255-55-23"
                >+7 (495) 255-55-23</a
              > -->

            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="false"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="1000"
              filename="summary"
              :pdf-quality="10"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <v-simple-table class="element">
                  <thead>
                    <tr>
                      <th class="text-left">№ платежа</th>
                      <th class="text-left">Дата платежа</th>
                      <th class="text-left">Сумма платежа</th>
                      <th class="text-left">Погашение долга</th>
                      <th class="text-left">Погашение процентов</th>
                      <th class="text-left">Остаток платежа</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in creditArray" :key="item.name">
                      <td>{{ item.id }}</td>
                      <td>{{ item.date }}</td>
                      <td>
                        {{
                          Math.ceil(item.summ).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          Math.ceil(item.debt).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          Math.ceil(item.procent).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          Math.abs(Math.ceil(item.balance)).toLocaleString(
                            "ru-RU"
                          ) + " ₽"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right">Всего выплат:</td>
                      <td>
                        {{
                          (
                            Math.ceil(this.X) * this.creditTimeValue
                          ).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          this.creditCashValue.toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          this.summProcentCredit.toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </section>
            </vue-html2pdf>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-5 mt-5 mb-5 no-print"
              @click="generateReport"
            >
              Распечатать
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--График платежей по платежу-->
      <v-dialog v-model="dialog3" width="1200" class="">
        <v-card class="">
          <v-toolbar dark color="primary">
            <v-spacer></v-spacer>

            <!-- <a class="phone" href="tel:+7 (495) 255-55-23"
                >+7 (495) 255-55-23</a
              > -->

            <v-btn icon dark @click="dialog3 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="false"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="1000"
              filename="summary"
              :pdf-quality="10"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <v-simple-table class="element">
                  <thead>
                    <tr>
                      <th class="text-left">№ платежа</th>
                      <th class="text-left">Дата платежа</th>
                      <th class="text-left">Сумма платежа</th>
                      <th class="text-left">Погашение долга</th>
                      <th class="text-left">Погашение процентов</th>
                      <th class="text-left">Остаток платежа</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in creditArray2" :key="item.name">
                      <td>{{ item.id }}</td>
                      <td>{{ item.date }}</td>
                      <td>
                        {{
                          Math.ceil(item.summ).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          Math.ceil(item.debt).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          Math.ceil(item.procent).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          Math.abs(Math.ceil(item.balance)).toLocaleString(
                            "ru-RU"
                          ) + " ₽"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-right">Всего выплат:</td>
                      <td>
                        {{
                          (
                            this.creditMonthPaySize2 * this.creditTimeValue2
                          ).toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          this.creditCashValueKnowMonthPaySize2.toLocaleString(
                            "ru-RU"
                          ) + " ₽"
                        }}
                      </td>
                      <td>
                        {{
                          this.summProcentCredit2.toLocaleString("ru-RU") + " ₽"
                        }}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </section>
            </vue-html2pdf>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-5 mt-5 mb-5 no-print"
              @click="generateReport"
            >
              Распечатать
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--форма-->

      <v-dialog
        v-model="this.$store.getters.MAINDIALOG"
        max-width="400px"
        persistent
      >
        <v-card>
          <v-icon @click="openForm()" class="formTitle">mdi-close</v-icon>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    class="pt-5"
                    label="Ваше имя"
                    append-icon="mdi-asterisk"
                    name="name-client"
                    v-model="name_client"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-mask="'+7 (###) ###-##-##'"
                    name="tel_client"
                    v-model="tel_client"
                    label="Телефон"
                    append-icon="mdi-asterisk"
                    required
                  />
                </v-col>
              </v-row>
              <v-btn
                large
                rounded
                color="secondary"
                width="100%"
                @click="submitForm"
                :disabled="
                  !this.soglasie || !this.name_client || !this.tel_client
                "
                >Отправить</v-btn
              >
            </v-container>

            <div class="soglasieSmallText">
              <input
                type="checkbox"
                id="scales"
                checked
                v-model="soglasie"
                color="primary"
              />
              Я прочитал(а)
              <a href="https://incomm-spb.ru/soglasie.php" target="_blank"
                >пользовательское соглашение</a
              >
              . Даю согласие на обработку персональных данных.
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-row class="pt-5">
      <v-col>
        <h2 class="mb-5 mt-5">Рассчитайте кредит в калькуляторе</h2>
        <section class="pt-5">
          <v-tabs v-model="tabsSelected">
            <!-- <span class="headerTypeCredit">Выберите тип расчета:</span> -->
            <v-tab
              v-for="tab in this.tabs"
              v-bind:key="tab.index"
              :href="tab.link"
            >
              {{ tab.name }}
            </v-tab>

            <v-tab-item value="one" class="pt-5 pr-5 pt-5 mt-5 pl-5">
              <!-- <v-card class="pl-5 pr-5 pb-5 mb-5" elevation="7" shaped> -->
              <v-row class="pt-5">
                <v-col cols="12" xxl="8" xl="8" lg="8" md="7" class="pt-5">
                  <v-text-field
                    height="10"
                    :rules="[
                      (v) =>
                        (v || '').length > 6 ||
                        'Сумма кредита должна быть не меньше 100 000 ₽',
                    ]"
                    @change="displayYears() || displayMonth()"
                    append-icon="mdi-pen"
                    v-model="fValue"
                    step="1000"
                    :min="this.minKredit"
                    :max="this.maxKredit"
                    outlined
                    label="Сумма кредита"
                    hint="от 100 000 ₽"
                    persistent-hint
                    :value="this.val"
                  />

                  <v-slider
                    @change="displayYears() || displayMonth()"
                    v-model="creditCashValue"
                    class="align-center testInput2"
                    :max="this.maxKredit"
                    track-color="rgba(0, 0, 0, 0.1)"
                    :value="this.val"
                  >
                  </v-slider>

                  <!--срок кредита  -->
                  <br />
                  <v-text-field
                    @change="displayYears() || displayMonth()"
                    step="1"
                    :min="this.minTimeKredit"
                    :max="this.maxTimeKredit"
                    outlined
                    label="Срок кредита"
                    hint="от 1 до 360 мес."
                    readonly
                    persistent-hint
                    :value="this.creditTimeValueInput"
                  />
                  <v-slider
                    @change="displayYears() || displayMonth()"
                    v-model="creditTimeValue"
                    step="1"
                    type="number"
                    class="align-center testInput3"
                    :min="this.minTimeKredit"
                    :max="this.maxTimeKredit"
                    track-color="rgba(0, 0, 0, 0.1)"
                    :value="this.val2"
                  >
                  </v-slider>
                  <!--ставка  -->
                  <br />
                  <v-text-field
                    append-icon="mdi-pen"
                    @change="displayYears() || displayMonth()"
                    label="Ставка"
                    v-model="bitKredit"
                    step="0.1"
                    hint="% в год"
                    persistent-hint
                    outlined
                  />
                  <v-slider
                    class="align-center testInput4"
                    @change="displayYears() || displayMonth()"
                    v-model="bitKredit"
                    step="0.1"
                    type="number"
                    :min="this.minBitCredit"
                    :max="this.maxBitCredit"
                    track-color="rgba(0, 0, 0, 0.1)"
                  >
                  </v-slider>
                </v-col>

                <v-col
                  cols="12"
                  xxl="3"
                  xl="3"
                  lg="3"
                  md="4"
                  offset="1"
                  class="pb-5"
                >
                  <v-container class="containerRight">
                    <div class="mx-auto">
                      <v-divider class="pb-5" />

                      <span class="bitLabel">Ежемесячный платеж</span>
                      <br />
                      <span class="bitValue">
                        <b>
                          {{
                            Math.ceil(this.X).toLocaleString("ru-RU") + " ₽"
                          }}</b
                        ></span
                      >
                      <br />
                      <br />
                      <v-divider class="pb-2" />
                      <v-btn
                        @click="openForm"
                        class="mt-5 ml-2"
                        rounded
                        color="secondary"
                        dark
                        x-large
                      >
                        Получить кредит
                      </v-btn>
                      <v-btn
                        @click="showCreditList()"
                        class="mt-5 ml-2"
                        rounded
                        color="grey lighten-3"
                        x-large
                        >График платежей</v-btn
                      >
                    </div>
                  </v-container>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="two" class="pt-5 pr-5 pt-5 mt-5 pl-5">
              <!-- <v-card class="pl-5 pr-5 pb-5 mb-5" elevation="7" shaped> -->
              <v-row class="pt-5">
                <v-col cols="12" xxl="8" xl="8" lg="8" md="7" class="pt-5">
                  <v-text-field
                    append-icon="mdi-pen"
                    v-model="fValue2"
                    step="1"
                    :max="this.maxKredit2"
                    outlined
                    label="Ежемесячный платеж"
                    hint="от 5 000 ₽"
                    persistent-hint
                    :value="creditMonthPaySize2"
                  />
                  <v-slider
                    @change="displayYears2() || displayMonth2()"
                    v-model="creditMonthPaySize2"
                    type="number"
                    class="align-center testInput2"
                    :max="this.maxKredit2"
                    track-color="rgba(0, 0, 0, 0.1)"
                  >
                  </v-slider>

                  <!--срок кредита  -->
                  <br />
                  <v-text-field
                    @change="displayYears2() || displayMonth2()"
                    step="1"
                    :min="this.minTimeKredit2"
                    :max="this.maxTimeKredit2"
                    outlined
                    label="Срок кредита"
                    hint="от 1 до 360 мес."
                    readonly
                    persistent-hint
                    :value="this.creditTimeValueInput2"
                  />
                  <v-slider
                    @change="displayYears2() || displayMonth2()"
                    v-model="creditTimeValue2"
                    step="1"
                    type="number"
                    class="align-center testInput3"
                    :min="this.minTimeKredit2"
                    :max="this.maxTimeKredit2"
                    track-color="rgba(0, 0, 0, 0.1)"
                  />
                  <!--Ставка-->
                  <br />
                  <v-text-field
                    append-icon="mdi-pen"
                    @change="displayYears2() || displayMonth2()"
                    label="Ставка"
                    v-model="bitKredit2"
                    step="0.1"
                    hint="% в год"
                    persistent-hint
                    outlined
                  />
                  <v-slider
                    class="align-center testInput4"
                    @change="displayYears2() || displayMonth2()"
                    v-model="bitKredit2"
                    step="0.1"
                    type="number"
                    :min="this.minBitCredit2"
                    :max="this.maxBitCredit2"
                    track-color="rgba(0, 0, 0, 0.1)"
                  >
                  </v-slider>
                  <!-- <v-text-field
                    class="pt-5"
                    outlined
                    readonly
                    label="Сумма кредита"
                    v-model="this.creditCashValueKnowMonthPaySize2"
                  /> -->
                </v-col>
                <v-col
                  cols="12"
                  xxl="3"
                  xl="3"
                  lg="3"
                  md="4"
                  offset="1"
                  class="pb-5"
                >
                  <v-container class="containerRight">
                    <div class="mx-auto">
                      <v-divider class="pb-2" />

                      <span class="bitLabel">Cумма кредита</span>
                      <br />
                      <span class="bitValue">
                        <b>
                          {{
                            Math.ceil(
                              this.creditCashValueKnowMonthPaySize2
                            ).toLocaleString("ru-RU") + " ₽"
                          }}</b
                        ></span
                      >
                      <br />
                      <br />
                      <v-divider class="pb-5" />

                      <v-btn
                        @click="dialog2 = !dialog2"
                        class="mt-5 ml-1"
                        rounded
                        color="secondary"
                        dark
                        x-large
                      >
                        Получить кредит
                      </v-btn>
                      <v-btn
                        @click="showCreditList2()"
                        class="mt-5 ml-1"
                        rounded
                        color="grey lighten-3"
                        x-large
                        >График платежей</v-btn
                      >
                    </div>
                  </v-container>
                </v-col>
              </v-row>
              <!-- </v-card> -->
            </v-tab-item>
            <v-tab-item value="three"> Tab content3 </v-tab-item>
          </v-tabs>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import axios from "axios";
export default {
  name: "CalcComp",
  components: {
    VueHtml2pdf,
  },
  data: () => ({
    name_client: "",
    tel_client: "+7(",
    // rule: {
    //   min(min, v) {
    //     return (
    //       v.length > min || 'Сумма кредита должна быть не меньше 100 000 ₽'
    //     )
    //   }
    // },
    // (v) =>
    //   (v && v >= 100000) || 'Сумма кредита должна быть не меньше 100 000 ₽'
    date: new Date().toISOString("de-DE").substr(0, 7),
    dateFormatted: new Date().toISOString("de-DE").substr(0, 7),
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    soglasie: true,
    creditArray: [],
    menu: false,
    modal: false,
    minKredit: 100000,
    maxKredit: 60000000,
    minBitCredit: 0.1,
    maxBitCredit: 100,
    minTimeKredit: 1,
    maxTimeKredit: 360,
    minTimeIpoteka: 120,
    maxTimeIpoteka: 360,
    tabsSelected: null,
    tabs: [
      { name: "По сумме", index: 1, link: "#one" },
      { name: "По платежу", index: 2, link: "#two" },
      // { name: 'Джинсы', index: 3, link: '#three' }
    ],
    creditCashValue: 800000,
    val: 0,
    creditCashValueStart: 800000,
    creditTimeValue: 180,
    ipotekaCashValue: 4000000,
    bitKredit: 6.3,
    bidIpoteka: 6.9,
    yearString: ["год", "года", "лет", ""],
    monthString: ["месяц", "месяца", "месяцев", ""],
    yearValue: "",
    start: 1,
    monthValue: "",
    testDate: "",
    // по размеру платежа
    creditMonthPaySize2: 10000,
    creditTimeValue2: 180,
    creditArray2: [],
    rule2: [
      (v) =>
        (v && v >= 5000) ||
        "Сумма ежемесячного платежа должна быть не меньше 5 000 ₽",
    ],
    minCreditMonthPaySize2: 5000,
    minKredit2: 0,
    maxKredit2: 500000,
    minTimeKredit2: 1,
    maxTimeKredit2: 360,
    date2: new Date().toISOString("de-DE").substr(0, 7),
    yearString2: ["год", "года", "лет", ""],
    monthString2: ["месяц", "месяца", "месяцев", ""],
    monthValue2: "",
    val2: 0,
    bitKredit2: 6.9,
    yearValue2: "",
    minBitCredit2: 0.1,
    maxBitCredit2: 100,
  }),

  methods: {
    openForm() {
      this.$store.commit("SET_MAINDIALOG");
    },
    showCreditList() {
      this.dialog = !this.dialog;
    },
    async submitForm() {
      const options = {
        method: "POST",
        url:
          "https://incomm-spb.ru/form.php?TITLE=" +
          this.name_client +
          "&PHONE=" +
          this.tel_client,
      };
      await axios
        .request(options)
        .then((response) => {
          console.log(response);
          (this.dialog4 = !this.dialog4),
            setTimeout(() => {
              window.location.reload();
              this.name_client = "";
              this.tel_client = "+7(";
            }, 3000);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    formatCreditCashValueInput(num) {
      return Number(num)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + " ");
    },
    parseCreditCashValueInput(text) {
      return Number(text.replace(/ /g, ""));
    },
    formatDate2(date) {
      return this.moment(date).format("LL");
    },
    formatDate3() {
      this.testDate = this.moment(this.date, "YYYY-MM").format("MM-YYYY");
    },
    addTimesCountToCreditArray() {
      this.creditArray = [];

      const monthlyInterestRate = this.bitKredit / 100 / 12;
      let currentBalance =
        this.X /
        ((monthlyInterestRate *
          Math.pow(1 + monthlyInterestRate, this.creditTimeValue)) /
          (Math.pow(1 + monthlyInterestRate, this.creditTimeValue) - 1));

      for (let i = 1; i <= this.creditTimeValue; i++) {
        const interestPayment = currentBalance * monthlyInterestRate;
        const principalPayment = this.X - interestPayment;
        currentBalance -= principalPayment;

        this.creditArray.push({
          id: i,
          date: this.moment(this.date).add(i, "M").format("MM-YYYY"),
          summ: this.X,
          debt: principalPayment.toFixed(2),
          procent: interestPayment.toFixed(2),
          balance: currentBalance.toFixed(2),
        });
      }
    },
    addMonths(date, months) {
      var d = date.getDate();
      date.setMonth(date.getMonth() + +months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
    },
    dialogOC() {
      this.dialog = !this.dialog;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
    displayYears() {
      this.addTimesCountToCreditArray();
      //yearString: ['год', 'года', 'лет', ''],

      if (this.creditTimeValue < 12) {
        this.yearValue = this.yearString[3];
      } else if (this.creditTimeValue >= 12 && this.creditTimeValue < 24) {
        this.yearValue = this.yearString[0];
      } else if (this.creditTimeValue >= 24 && this.creditTimeValue < 60) {
        this.yearValue = this.yearString[1];
      } else if (this.creditCashValue >= 60 && this.creditTimeValue < 252) {
        this.yearValue = this.yearString[2];
      } else if (this.creditTimeValue >= 252 && this.creditTimeValue < 263) {
        this.yearValue = this.yearString[0];
      } else if (this.creditTimeValue >= 264 && this.creditTimeValue < 300) {
        this.yearValue = this.yearString[1];
      } else if (this.creditTimeValue >= 300) {
        this.yearValue = this.yearString[2];
      }
    },
    ProcentPart(a) {
      return Math.ceil(this.P * a);
    },
    CreditPart(a) {
      return Math.ceil(this.X) - a;
    },
    Ostatok(a, b) {
      return a - b;
    },
    displayMonth() {
      if (this.countMonthAfterYear < 2 && this.countMonthAfterYear > 0) {
        this.monthValue = this.monthString[0];
      } else if (
        this.countMonthAfterYear > 1 &&
        this.countMonthAfterYear <= 4
      ) {
        this.monthValue = this.monthString[1];
      } else if (this.countMonthAfterYear >= 5) {
        this.monthValue = this.monthString[2];
      } else {
        this.monthValue = this.monthString[3];
      }
    },

    // по размеру платежа
    showCreditList2() {
      this.dialog3 = !this.dialog3;
    },
    formatCreditCashValueInput2(num) {
      return Number(num)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + " ");
    },
    parseCreditCashValueInput2(text) {
      return Number(text.replace(/ /g, ""));
    },
    displayYears2() {
      ////yearString: ['год', 'года', 'лет', ''],
      this.addTimesCountToCreditArray2();
      if (this.countYears2 < 1) {
        this.yearValue2 = this.yearString[3];
      } else if (this.countYears2 == 1) {
        this.yearValue2 = this.yearString[0];
      } else if (this.countYears2 > 1 && this.countYears2 < 5) {
        this.yearValue2 = this.yearString[1];
      } else if (this.countYears2 > 4 && this.countYears2 < 21) {
        this.yearValue2 = this.yearString[2];
      } else if (this.countYears2 == 21) {
        this.yearValue2 = this.yearString[0];
      } else if (this.countYears2 > 21 && this.countYears2 < 25) {
        this.yearValue2 = this.yearString[1];
      } else if (this.countYears2 >= 25) {
        this.yearValue2 = this.yearString[2];
      }
    },
    displayMonth2() {
      //['месяц', 'месяца', 'месяцев', ''],
      if (this.countMonthAfterYear2 == 0) {
        this.monthValue2 = this.monthString2[3];
      } else if (this.countMonthAfterYear2 < 2) {
        this.monthValue2 = this.monthString2[0];
      } else if (
        this.countMonthAfterYear2 > 1 &&
        this.countMonthAfterYear2 < 5
      ) {
        this.monthValue2 = this.monthString2[1];
      } else if (this.countMonthAfterYear2 > 4) {
        this.monthValue2 = this.monthString2[2];
      }
    },
    addTimesCountToCreditArray2() {
      this.creditArray2 = [];

      const monthlyInterestRate2 = this.bitKredit2 / 100 / 12;
      let currentBalance2 =
        this.creditMonthPaySize2 /
        ((monthlyInterestRate2 *
          Math.pow(1 + monthlyInterestRate2, this.creditTimeValue2)) /
          (Math.pow(1 + monthlyInterestRate2, this.creditTimeValue2) - 1));

      for (let i = 1; i <= this.creditTimeValue2; i++) {
        const interestPayment2 = currentBalance2 * monthlyInterestRate2;
        const principalPayment2 = this.creditMonthPaySize2 - interestPayment2;
        currentBalance2 -= principalPayment2;

        this.creditArray2.push({
          id: i,
          date: this.moment(this.date2).add(i, "M").format("MM-YYYY"),
          summ: this.creditMonthPaySize2,
          debt: principalPayment2.toFixed(2),
          procent: interestPayment2.toFixed(2),
          balance: currentBalance2.toFixed(2),
        });
      }
    },
  },

  computed: {
    creditTimeValueInput() {
      let text = "";
      if (this.countYears) {
        text = this.countYears + " " + this.yearValue + " ";
      }
      if (this.countMonthAfterYear) {
        text = this.countMonthAfterYear + " " + this.monthValue;
      }
      if (this.countMonthAfterYear && this.countYears) {
        text =
          this.countYears +
          " " +
          this.yearValue +
          " " +
          "и " +
          this.countMonthAfterYear +
          " " +
          this.monthValue;
      }
      return text;
    },
    fValue: {
      // геттер:
      get: function () {
        return this.formatCreditCashValueInput(this.creditCashValue);
      },
      // сеттер:
      set: function (newValue) {
        this.creditCashValue = this.parseCreditCashValueInput(newValue);
      },
    },
    firstProcentPart() {
      return Math.ceil(this.P * this.creditCashValue);
    },
    firstCreditPart() {
      return Math.ceil(this.X) - this.firstProcentPart;
    },
    firstOstatok() {
      return this.creditCashValue - this.firstCreditPart;
    },
    timeTest() {
      return this.date;
    },
    allSummCreditWithProcent() {
      return Math.ceil(this.X) * this.creditTimeValue;
    },
    monthCalc() {
      return this.addMonths(new Date(), this.creditTimeValue).toString();
    },
    formatCreditCashValue: function () {
      return this.creditCashValue.toLocaleString("ru-RU");
    },
    fullSummKreditByYear: function () {
      return (
        (((this.summProcentCredit / this.creditTimeValue) * 12) /
          this.creditCashValue) *
        100
      );
    },
    procentKreditByMonth: function () {
      return this.bitKredit / 12;
    },
    countYears: function () {
      return Math.floor(this.creditTimeValue / 12);
    },
    countMonthAfterYear: function () {
      return this.creditTimeValue % 12;
    },
    countYearsByMonthCredit: function () {
      return this.countYears * this.creditTimeValue;
    },
    countProcentAllPeriod: function () {
      return (this.summProcentCredit / this.creditCashValue) * 100;
    },
    S: function () {
      return this.creditCashValue;
    },
    P: function () {
      return this.bitKredit / 100 / 12;
    },
    P1: function () {
      return this.P + 1;
    },
    X: function () {
      return (
        this.S *
        (this.P + this.P / (Math.pow(this.P1, this.creditTimeValue) - 1))
      );
    },
    summProcentCredit: function () {
      return Math.ceil(this.X * this.creditTimeValue - this.creditCashValue);
    },
    // по размеру платежа
    summProcentCredit2: function () {
      return Math.ceil(
        this.creditMonthPaySize2 * this.creditTimeValue2 -
          this.creditCashValueKnowMonthPaySize2
      );
    },
    fValue2: {
      // геттер:
      get: function () {
        return this.formatCreditCashValueInput2(this.creditMonthPaySize2);
      },
      // сеттер:
      set: function (newValue2) {
        this.creditMonthPaySize2 = this.parseCreditCashValueInput2(newValue2);
      },
    },
    creditCashValueKnowMonthPaySize2: function () {
      return (
        Math.round(
          this.creditMonthPaySize2 /
            (this.P2 +
              this.P2 / (Math.pow(this.P12, this.creditTimeValue2) - 1)) /
            10
        ) * 10
      );
    },
    P2: function () {
      return this.bitKredit2 / 100 / 12;
    },
    P12: function () {
      return this.P2 + 1;
    },
    countYears2: function () {
      return Math.floor(this.creditTimeValue2 / 12);
    },
    countMonthAfterYear2: function () {
      return this.creditTimeValue2 % 12;
    },
    countYearsByMonthCredit2: function () {
      return this.countYears2 * this.creditTimeValue2;
    },
    creditTimeValueInput2() {
      let text2 = "";
      if (this.countYears2) {
        text2 = this.countYears2 + " " + this.yearValue2 + " ";
      }
      if (this.countMonthAfterYear2) {
        text2 = this.countMonthAfterYear2 + " " + this.monthValue2;
      }
      if (this.countMonthAfterYear2 && this.countYears2) {
        text2 =
          this.countYears2 +
          " " +
          this.yearValue2 +
          " " +
          "и " +
          this.countMonthAfterYear2 +
          " " +
          this.monthValue2;
      }
      return text2;
    },
  },
  watch: {
    creditCashValue(val) {
      let newVal = Math.round(val / 1000) * 1000;
      if (newVal !== val && val > 100000) {
        this.creditCashValue = newVal;
      }
    },
  },
  created() {
    this.formatDate3();
    this.displayYears();
    this.displayMonth();
    this.addTimesCountToCreditArray();
    // по размеру платежа
    this.displayYears2();
    this.displayMonth2();
    this.addTimesCountToCreditArray2();
  },
  mounted() {},
};
</script>

<style>
.container {
  max-width: 1397px;
}
.v-btn__content {
  letter-spacing: normal !important;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 9px !important;
}
input {
  padding-left: 8px !important;
}
.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.4);
}
.containerRight {
  padding: 10px 0px 0px 0px !important;
}
.v-item-group {
  margin-left: 20px;
}
.products-title {
  border-left: 4px solid #ffed00;
  height: 34px;
  line-height: 34px;
  font-size: 28px;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  margin-left: 39px;
}
.headerTypeCredit {
  font-weight: bold;
  padding-top: 14px;
  color: black;
}
.vtext {
  width: 182px;
}
.vtext2 {
  width: 182px;
}
.vtext .v-input {
  font-size: 3em !important;
}
.vtext input {
  max-height: 100px !important;
}
.labelXL .v-label {
  font-size: 21px;
  top: 0px;
}
.v-label {
  font-weight: bold !important;
  padding-left: 10px !important;
  color: #7b7b7b !important;
}
input {
  font-weight: bold !important;
  font-size: 1.2em !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.testInput2 {
  position: absolute;
  width: 65.5% !important;
  top: 107px;
  left: 12px;
}
.testInput3 {
  position: absolute;
  width: 65.5% !important;
  top: 217px;
  left: 12px;
}
.testInput4 {
  position: absolute;
  width: 65.5% !important;
  top: 327px;
  left: 12px;
}
.v-slider__track-background {
  right: 26px !important;
  left: 0px !important;
}
.v-slider--horizontal {
  margin-right: 16px !important;
}
.phone {
  color: #fff !important;
  text-decoration: none;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.bitWhidth {
  width: 150px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.v-tab--active {
  background-color: #ffed00 !important;
  color: #fff !important;
  border-radius: 90px !important;
  padding-top: 2px;
  text-transform: none !important;
  font-size: large;
  font-weight: 500;
  letter-spacing: normal;
}
.v-tab {
  padding-top: 2px;
  border-radius: 90px !important;
  text-transform: none !important;
  font-size: large;
  background-color: #f4f4f4;
  font-weight: bold;
  letter-spacing: normal;
  margin-right: 7px;
  min-width: 138px !important;
}
.v-tab:hover {
  border-radius: 90px !important;
  background-color: #bdb101;
}
.v-tab:active {
  border-radius: 90px !important;
}
.v-tab::before {
  border-radius: 90px !important;
}
.v-tabs-slider-wrapper {
  height: 0px !important;
}
.v-application .v-application--wrap * {
  font-family: "Nissan Brand" !important;
}
.v-slide-group__wrapper {
  margin-left: 20px;
}
.bitLabel {
  color: #7d7d7d;
  font-size: 18px;
}
.bitValue {
  font-size: 28px;
  color: #000;
}
.mdi-asterisk {
  font-size: 10px !important;
  color: red !important;
}
.formTitle {
  position: absolute !important;
  top: 4px;
  right: 7px;
  color: #45b3b1 !important;
}
.dialogForm {
  border-radius: 90px !important;
}
.soglasieSmallText {
  font-size: small;
}
input[type="checkbox"] {
  accent-color: #ffed00 !important;
}
.v-btn__content {
  text-transform: none !important;
  font-size: 1.2rem !important;
}

@media (min-width: 1200px) and (max-width: 1263px) {
  .testInput2 {
    width: 57.5% !important;
  }
  .testInput3 {
    width: 57.5% !important;
  }
  .testInput4 {
    width: 57.5% !important;
  }
}
@media (min-width: 960px) and (max-width: 1199px) {
  .testInput2 {
    width: 57.5% !important;
  }
  .testInput3 {
    width: 57.5% !important;
  }
  .testInput4 {
    width: 57.5% !important;
  }
}

@media screen and (min-width: 671px) and (max-width: 959px) {
  .v-application--is-ltr .offset-1 {
    margin-left: 0 !important;
  }
  .testInput2 {
    width: 98% !important;
  }
  .testInput3 {
    width: 98% !important;
  }
  .testInput4 {
    width: 98% !important;
  }
}

@media screen and (min-width: 555px) and (max-width: 670px) {
  .v-application--is-ltr .offset-1 {
    margin-left: 0 !important;
  }
  .testInput2 {
    width: 98% !important;
  }
  .testInput3 {
    width: 98% !important;
  }
  .testInput4 {
    width: 98% !important;
  }
}

@media only screen and (min-width: 501px) and (max-width: 554px) {
  .v-application--is-ltr .offset-1 {
    margin-left: 0 !important;
  }
  .testInput2 {
    width: 98% !important;
  }
  .testInput3 {
    width: 98% !important;
  }
  .testInput4 {
    width: 98% !important;
  }
}
@media only screen and (min-width: 425px) and (max-width: 500px) {
  .v-application--is-ltr .offset-1 {
    margin-left: 0 !important;
  }
  .testInput2 {
    width: 96% !important;
  }
  .testInput3 {
    width: 96% !important;
  }
  .testInput4 {
    width: 96% !important;
  }
}

@media only screen and (min-width: 100px) and (max-width: 424px) {
  .v-application--is-ltr .offset-1 {
    margin-left: 0 !important;
  }
  .testInput2 {
    width: 95% !important;
  }
  .testInput3 {
    width: 95% !important;
  }
  .testInput4 {
    width: 95% !important;
  }
}
</style>
