<template>
  <section class="wideContainer">
    <v-card class="banner">
      <v-container>
        <h1 class="whiteColor pt-8 pb-5">
          Кредит<br />
          без отказа
        </h1>
        <v-img class="mainImage pt-5" src="../assets/slide-pics.png" />
      </v-container>

      <v-container>
        <br />
        <v-row class="pb-10">
          <v-col cols="12" xxl="4" xl="4" lg="4" md="4">
            <v-btn class="bannerBtn" @click="openForm">Отправить заявку</v-btn>
          </v-col>
          <v-col class="" cols="12" xxl="4" xl="4" lg="4" md="4">
            <span class="checkList">
              <v-icon class="checkListDots">mdi-exclamation</v-icon>Без
              подтверждения дохода
            </span>

            <span class="checkList">
              <v-icon class="checkListDots">mdi-exclamation</v-icon>С
              просрочками и микрозаймами
            </span>
          </v-col>
          <v-col class="" xxl="4" xl="4" lg="4" md="4">
            <span class="checkList">
              <v-icon class="checkListDots">mdi-exclamation</v-icon>С плохой КИ
            </span>

            <span class="checkList">
              <v-icon class="checkListDots">mdi-exclamation</v-icon>Деньги в
              день обращения
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "BannerComp",
  data: () => ({}),
  methods: {
    openForm() {
      this.$store.commit("SET_MAINDIALOG");
    },
  },
};
</script>
<style>
.banner {
  background: linear-gradient(#39ab92, #319e9a);
}

.bannerBtn {
  background-color: #ffed00 !important;
  text-transform: none;
  font-size: 24px !important;
  letter-spacing: 0;
  padding: 38px !important;
}
.checkList {
  display: block !important;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  font-weight: normal;
  text-shadow: 1px 1px 11px #7c7a7a;
}
.checkListDots {
  background-color: #ffed00;
  border-radius: 90%;
  margin-right: 5px;
}
.mainImage {
  width: 100%;
}
</style>
