import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{class:{
      headOnScrollNone: _vm.scrollPosition < 1,
      headOnScroll: _vm.scrollPosition > 1,
    },attrs:{"app":"","color":"white","height":"90"}},[_c(VContainer,{staticClass:"d-flex"},[_c('a',{attrs:{"href":""}},[_c('div',{staticClass:"d-flex align-center logo-text"},[_c(VImg,{staticClass:"shrink mr-5",attrs:{"alt":"логотип","contain":"","src":require("../src/assets/logo.png"),"transition":"scale-transition","width":"48"}}),_vm._v(" МосИнКомм ")],1)]),_c(VSpacer),_c('a',{staticClass:"headPhone2",attrs:{"href":"tel:+74954318843"}},[_c(VIcon,{staticClass:"phoneIcon2",attrs:{"color":"black"}},[_vm._v(" mdi-phone-outline ")])],1),_c('a',{staticClass:"headPhone",attrs:{"href":"tel:+74954318843"}},[_vm._v(" +7 (495) 431-88-43 ")]),_c(VBtn,{staticClass:"btn-head ml-10",attrs:{"outlined":""},on:{"click":_vm.openForm}},[_vm._v("Перезвоните мне")])],1)],1),_c(VMain,[_c('BannerComp'),_c('CalcComp'),_c('SquaresComp'),_c('FaqComp'),_c('AdBlockComp'),_c('FooterComp')],1),_c(VBtn,{staticClass:"floatBtn",attrs:{"color":"secondary"},on:{"click":_vm.openForm}},[_vm._v("Проверить кредитную историю")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }