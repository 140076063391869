import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@/styles/variable.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#319e9a",
        secondary: "#ffed00", // #FFCDD2
        // accent: colors.indigo.base, // #3F51B5
      },
    },
  },
});
