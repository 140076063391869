import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import moment from "moment";
import store from "./store";

moment.locale("ru");

Vue.prototype.moment = moment;
Vue.config.productionTip = false;

Vue.use(VueMask);

new Vue({
  vuetify,
  store,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
